// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fragnoli-photos-js": () => import("./../../../src/pages/fragnoli-photos.js" /* webpackChunkName: "component---src-pages-fragnoli-photos-js" */),
  "component---src-pages-freelance-js": () => import("./../../../src/pages/freelance.js" /* webpackChunkName: "component---src-pages-freelance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modern-trousseau-js": () => import("./../../../src/pages/modern-trousseau.js" /* webpackChunkName: "component---src-pages-modern-trousseau-js" */),
  "component---src-pages-national-parks-js": () => import("./../../../src/pages/national-parks.js" /* webpackChunkName: "component---src-pages-national-parks-js" */),
  "component---src-pages-page-2-js-00-00-17-524-js": () => import("./../../../src/pages/page-2.js 00-00-17-524.js" /* webpackChunkName: "component---src-pages-page-2-js-00-00-17-524-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-resume-hard-copy-js": () => import("./../../../src/pages/resume-hard-copy.js" /* webpackChunkName: "component---src-pages-resume-hard-copy-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-rochester-breweries-js": () => import("./../../../src/pages/rochester-breweries.js" /* webpackChunkName: "component---src-pages-rochester-breweries-js" */),
  "component---src-pages-tic-tac-toe-js": () => import("./../../../src/pages/tic-tac-toe.js" /* webpackChunkName: "component---src-pages-tic-tac-toe-js" */),
  "component---src-pages-trivia-game-js": () => import("./../../../src/pages/trivia-game.js" /* webpackChunkName: "component---src-pages-trivia-game-js" */)
}

